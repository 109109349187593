import styled, { keyframes } from "styled-components";

export const OfferHeader = styled.h2`
  font-size: 45px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: unset;
    padding: unset;
    padding: 10px 0;
    font-size: 28px;
  }
`;

export const OfferRow = styled.div<{ isVisible: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 5%;
  row-gap: 2rem;
  opacity: ${({ isVisible }) => (isVisible ? "100%" : "0")};
  transition: opacity 1.5s ease-in;

  @media (width < 880px) {
    flex-direction: column;
    gap: 15px;
    align-items: center;
  }

  @media (width < 1510px) {
    gap: 5%;
    row-gap: 4rem;
  }

  @media (width < 1100px) {
    gap: 10%;
    row-gap: 4rem;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const OfferTile = styled.div`
  box-sizing: border-box;
  width: 21.2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  /* background-color: #fae8ec; */
  gap: 50px;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(
    90deg,
    rgba(91, 26, 92, 1) 0%,
    rgba(41, 53, 86, 1) 50%,
    rgba(14, 9, 43, 1) 100%
  );
  -webkit-box-shadow: -6px 8px 24px 0px rgba(91, 26, 92, 1);
  -moz-box-shadow: -6px 8px 24px 0px rgba(91, 26, 92, 1);
  box-shadow: -6px 8px 24px 0px rgba(91, 26, 92, 1);

  @media (width < 1510px) {
    width: 30%;
  }

  @media (width < 1100px) {
    width: 45%;
  }

  @media (width < 880px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const TileInformation = styled.div`
  height: --webkit-fita;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background-color: #fff;
`;

export const TileHeader = styled.h6`
  font-size: 2rem;
  margin: unset;
`;

export const DescriptionText = styled.p`
  align-self: flex-end;
  line-height: 1.5rem;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

export const ShowMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px 20px;
  background-image: linear-gradient(
    90deg,
    rgba(91, 26, 92, 1) 0%,
    rgba(41, 53, 86, 1) 50%,
    rgba(14, 9, 43, 1) 100%
  );
  color: #fff;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
