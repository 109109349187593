import styled from "styled-components";

export const Tile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  color: #fff;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  background-color: #fff;
`;

export const Icon = styled.img`
  width: 40px;
  height: 40px;
`;

export const Header = styled.h3`
  margin: 0;
`;

export const Text = styled.p`
  margin: 0;
`;
