import React from "react";
import * as S from "./ContactUs.styles";

const ContanctUs = () => {
  return (
    <S.Tile>
      <S.IconContainer>
        <S.Icon
          src={`${process.env.PUBLIC_URL}/assets/email.svg`}
          alt="Email_Us_Icon"
        />
      </S.IconContainer>
      <S.Header>EMAIL</S.Header>
      <S.Text>kontakt@gwmdigital.pl</S.Text>
    </S.Tile>
  );
};

export default ContanctUs;
