import React, { useState } from "react";
import Slider from "react-slick";
import * as S from "./SliderCart.styles";

const SliderCart = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7500,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "100px",
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1510,
        settings: {
          slidesToShow: 3,
          centerPadding: "0",
          centerMode: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          centerPadding: "0",
          centerMode: true,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setActiveSlide(next),
  };

  const card = [
    {
      text: `Czy wiesz, że 93% wszystkich doświadczeń online zaczyna się od wyszukiwarki? Jeśli Twoja firma nie jest widoczna w wynikach wyszukiwania, tracisz ogromną szansę na przyciągnięcie nowych klientów.`,
      icon: "wild_2.svg",
    },
    {
      text: `Ponad 80% użytkowników internetu dokonuje zakupów online lub szuka firm usługowych. Skuteczny marketing internetowy sprawia, że Twoja oferta jest tam, gdzie klienci jej szukają.`,
      icon: "wild_3.svg",
    },
    {
      text: `Media społecznościowe mają ponad 4,5 miliarda aktywnych użytkowników. To olbrzymia grupa potencjalnych klientów, do której możesz dotrzeć za pomocą strategii marketingu internetowego.`,
      icon: "wild_2.svg",
    },
    {
      text: `Badania pokazują, że firmy, które inwestują w marketing internetowy, notują o 3,5 razy wyższy wzrost przychodów niż te, które tego nie robią. Odpowiednio dobrana strategia online może znacząco zwiększyć Twoje zyski.`,
      icon: "wild.svg",
    },
    {
      text: `Ponad 70% konsumentów dokonuje decyzji zakupowych na podstawie treści, które znajdą w internecie. Tworzenie wartościowych treści i optymalizacja ich pod kątem wyszukiwarek to klucz do budowania zaufania i lojalności klientów.`,
      icon: "wild_2.svg",
    },
    {
      text: `Czy wiesz, że reklamy online mogą być nawet 60% tańsze niż tradycyjne formy reklamy? Marketing internetowy pozwala precyzyjnie dotrzeć do odpowiedniej grupy docelowej, minimalizując koszty i maksymalizując zwrot z inwestycji.`,
      icon: "wild_3.svg",
    },
    {
      text: `Mobilne wyszukiwanie stanowi obecnie ponad 50% globalnego ruchu internetowego. Dzięki marketingowi internetowemu możesz docierać do klientów w dowolnym miejscu i czasie, niezależnie od tego, z jakiego urządzenia korzystają.`,
      icon: "wild_5.svg",
    },
  ];

  return (
    <Slider {...settings}>
      {card.map(({ text, icon }, index) => {
        const positionX = Math.random() * 80;
        const positionY = Math.random() * 70;
        return (
          <S.Slide active={activeSlide === index}>
            <S.Text active={activeSlide === index}>{text}</S.Text>
            <S.Icon
              positionX={String(positionX)}
              positionY={positionY < 10 ? "20%" : String(positionY)}
            >
              <S.Img
                src={`${process.env.PUBLIC_URL}/assets/${icon}`}
                alt="Chaos_Icon"
              />
            </S.Icon>
          </S.Slide>
        );
      })}
    </Slider>
  );
};

export default SliderCart;
