import * as S from "./App.styles";
import MainApp from "./MainApp/MainApp";
import WelcomeContainer from "./WelcomeContainer/WelcomeContainer";
import { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Analytics } from "@vercel/analytics/react";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #fff;
  }
  
  html {
    scroll-behavior: smooth;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  };
  ::-webkit-scrollbar-thumb {
    background-color: #555557;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
  };
  ::-webkit-scrollbar-track {
    inset: 0 0 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Analytics />
      <WelcomeContainer />
      <MainApp />
    </>
  );
}

export default App;
