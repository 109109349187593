import React, { useEffect, useRef, useState } from "react";
import * as S from "./Circle.styles";

const Circle: React.FC<{
  details: {
    positionX: string;
    positionY: string;
    width: string;
    isOnText: boolean;
  };
  index: number;
}> = ({ details, index }) => {
  const { positionX, positionY, width, isOnText } = details;

  const [isVisible, setIsVisible] = useState(false);
  const domRef = useRef<any>();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(domRef.current);
    return () => observer.disconnect();
  }, [domRef.current]);

  return (
    <S.CircleContainer
      isVisible={isVisible}
      ref={domRef}
      isOnText={isOnText}
      index={index}
      circleSize={width}
      positionX={positionX}
      positionY={positionY}
    />
  );
};

export default Circle;
