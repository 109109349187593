import React, { useEffect, useState } from "react";
import * as S from "./GoBack.styles";

const GoBack = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [shouldGoBackBeVisible, setShoulGoBackBeVisible] = useState(false);
  const [isGoBackVisible, setIsGoBackVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 800 && !shouldGoBackBeVisible) {
      setShoulGoBackBeVisible(true);
      setIsGoBackVisible(true);
    } else if (scrollPosition < 800 && shouldGoBackBeVisible) {
      setShoulGoBackBeVisible(false);
    }
  }, [scrollPosition]);

  useEffect(() => {
    let timeout;
    if (!shouldGoBackBeVisible) {
      timeout = setTimeout(() => {
        setIsGoBackVisible(false);
      }, 950);
    }
    return () => clearTimeout(timeout);
  }, [shouldGoBackBeVisible]);

  return (
    <S.GoBack
      href="#"
      shouldBeVisible={shouldGoBackBeVisible}
      isVisible={isGoBackVisible}
      onAnimationEnd={() => setIsGoBackVisible(shouldGoBackBeVisible)}
    >
      <S.GoBackIcon
        src={`${process.env.PUBLIC_URL}/assets/arrow_up.svg`}
        alt="Go_Back_Icon"
      />
    </S.GoBack>
  );
};

export default GoBack;
