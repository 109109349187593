import styled from "styled-components";

export const CircleContainer = styled.div<{
  circleSize: string;
  positionX: string;
  positionY: string;
  index: number;
  isOnText: boolean;
  isVisible: boolean;
}>`
  width: ${({ circleSize }) =>
    Number(circleSize) < 100 ? "100px" : `${circleSize}px`};
  height: ${({ circleSize }) =>
    Number(circleSize) < 100 ? "100px" : `${circleSize}px`};
  background-position: top left;
  border-radius: 100%;
  position: absolute;
  top: ${({ positionY }) => positionY};
  left: ${({ positionX }) => positionX};
  overflow: hidden;
  background: ${({ index }) =>
    `url(${process.env.PUBLIC_URL}/assets/circle${index}.webp)`};
  background-size: cover;
  opacity: ${({ isOnText }) => (isOnText ? "50%" : "100%")};
  transform: scale(1)
    ${({ isVisible }) => (isVisible ? "translateY(0)" : "translateY(300px)")};
  transition: transform 1s ease; /* Przejście na właściwości transform */

  &&:hover {
    transform: scale(1.2);
    transition: 2s;
  }

  @media (max-width: 768px) {
    transform: scale(1)
      ${({ isVisible }) => (isVisible ? "translateY(0)" : "translateY(20px)")};
  }

  &&:hover {
    transform: scale(1.03);
    transition: 2s;
  }
`;
