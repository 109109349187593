import styled, { keyframes } from "styled-components";

const showUP = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const dissapear = keyframes`
  from {
    opacity: 1;
  } to {
    opacity: 0;
  }
`;

export const GoBack = styled.a<{
  isVisible: boolean;
  shouldBeVisible: boolean;
}>`
  position: fixed;
  bottom: 5%;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px 20px;
  background-image: linear-gradient(
    90deg,
    rgba(91, 26, 92, 1) 0%,
    rgba(41, 53, 86, 1) 50%,
    rgba(14, 9, 43, 1) 100%
  );
  color: #fff;
  cursor: pointer;
  z-index: 100;
  animation: ${({ shouldBeVisible }) => (shouldBeVisible ? showUP : dissapear)}
    1s ease-out;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
`;

export const GoBackIcon = styled.img`
  width: 20px;
  height: 25px;
`;
