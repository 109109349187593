import * as S from "./MainApp.styles";
import Offer from "./Offer/Offer";
import SliderCart from "./Slider/SliderCart";
import Circle from "./Circle/Circle";
import { useEffect, useState } from "react";
import GoBack from "./GoBack/GoBack";
import ContanctUs from "./ContanctUs/ContanctUs";

function MainApp() {
  const width = window.innerWidth;

  const circles = [
    {
      positionX: `63%`,
      positionY: `17%`,
      isOnText: false,
      width: `100`,
    },
    {
      positionX: `10%`,
      positionY: `40%`,
      width: `200`,
      isOnText: false,
    },
    {
      positionX: `78%`,
      positionY: `62%`,
      width: `250`,
      isOnText: true,
    },
    {
      positionX: `100%`,
      positionY: `10%`,
      width: `170`,
      isOnText: true,
    },
    {
      positionX: `150%`,
      positionY: `90%`,
      width: `110`,
      isOnText: true,
    },
    {
      positionX: `200%`,
      positionY: `22%`,
      width: `140`,
      isOnText: true,
    },
    {
      positionX: `250%`,
      positionY: `80%`,
      width: `220`,
      isOnText: true,
    },
    {
      positionX: `0%`,
      positionY: `100%`,
      width: `120`,
      isOnText: false,
    },
  ];

  const mobileCircles = [
    {
      positionX: `63%`,
      positionY: `3%`,
      isOnText: true,
      width: `140`,
    },
    {
      positionX: `10%`,
      positionY: `31%`,
      width: `200`,
      isOnText: true,
    },
    {
      positionX: `42%`,
      positionY: `82%`,
      width: `80`,
      isOnText: true,
    },
  ];

  return (
    <S.MainPageSection data-test-id="Main_Page_Section">
      {width > 768 ? <GoBack /> : null}
      <S.UpperSection data-test-id="Upper_Section">
        <S.MenuRow data-test-id="Menu_Row">
          <S.Logo
            src={`${process.env.PUBLIC_URL}/assets/logo_white.svg`}
            alt="GWM_Logo"
          />
          {width > 768 ? (
            <S.Links>
              <S.LinkElement>
                <S.Link href={`#About`}>
                  <h3>O nas</h3>
                </S.Link>
              </S.LinkElement>
              <S.LinkElement>
                <S.Link href={"#Offer"}>
                  <h3>Oferta</h3>
                </S.Link>
              </S.LinkElement>
              {/* <S.LinkElement>
                <S.Link>Nasze realizacje</S.Link>
              </S.LinkElement> */}
            </S.Links>
          ) : null}
        </S.MenuRow>
        <S.InfoSection>
          <S.InfoHeader>
            Pozwól o sobie
            <br /> usłyszeć
          </S.InfoHeader>
          <S.InfoText>
            Pomagamy naszym klientom zaistnieć w sieci,
            <br /> projektując nowoczesne, responsywne strony oraz prowadząc
            skuteczne kampanie reklamowe,
            <br />
            które zwiększają ich widoczność i zasięg w Internecie.
            <br /> Dzięki nam Twoja firma osiągnie nowe poziomy sukcesu online.
          </S.InfoText>
        </S.InfoSection>
      </S.UpperSection>
      <S.MiddleSection data-test-id="Middle_Section">
        <S.WaveImage
          src={`${process.env.PUBLIC_URL}/assets/wave.svg`}
          data-test-id="Wave_Image"
          alt="Wave_Image"
        />
        <S.SliderSection data-test-id="Slider_Section">
          <SliderCart />
        </S.SliderSection>
        <S.AboutSection id="About" data-test-id="About_Section">
          {width > 768 ? (
            <S.CircleContainer data-test-id="Circle_Container">
              {circles.map((details, index) => (
                <Circle details={details} index={index + 1} />
              ))}
            </S.CircleContainer>
          ) : null}
          <S.AboutRow data-test-id="About_Row">
            <S.AboutUsHeader data-test-id="About_Us_Header">
              O nas
            </S.AboutUsHeader>
            <S.OfferText data-test-id="Offer_Text">
              GW&M Digital to firma, której misją jest wspieranie lokalnych
              biznesów w dynamicznie zmieniającym się świecie online.
              Specjalizujemy się w przenoszeniu firm do przestrzeni cyfrowej,
              pomagając im odnaleźć swoje miejsce w nowej rzeczywistości. Naszym
              celem jest umożliwienie lokalnym przedsiębiorstwom skutecznego
              funkcjonowania i rozwoju w erze cyfrowej, opierając się na naszym
              wieloletnim doświadczeniu i wiedzy. Zajmiemy się Twoją obecnością
              w sieci, abyś mógł skupić się na tym, co najważniejsze –
              prowadzeniu biznesu.
            </S.OfferText>
            {width < 768
              ? mobileCircles.map((details, index) => (
                  <Circle details={details} index={index + 1} />
                ))
              : null}
          </S.AboutRow>
        </S.AboutSection>
        <S.OfferSection data-test-id="Offer_Section" id="Offer">
          <Offer />
        </S.OfferSection>
        <S.ContactUs>
          <S.ContactUsHeader>Skontakuj się z nami</S.ContactUsHeader>
          <ContanctUs />
        </S.ContactUs>
      </S.MiddleSection>
    </S.MainPageSection>
  );
}

export default MainApp;
