import React, { useEffect, useState } from "react";
import * as S from "./WelcomeContainer.styles";

const WelcomeContainer = () => {
  const [isWelcomeVisible, setIsWelcomeVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsWelcomeVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <S.WelcomeDiv data-test-id="Welcome_Div" isVisible={isWelcomeVisible}>
      <S.LogoImg
        src={`${process.env.PUBLIC_URL}/assets/new_logo.svg`}
        alt="GWM_Logo"
      />
    </S.WelcomeDiv>
  );
};

export default WelcomeContainer;
