import styled, { keyframes } from "styled-components";

const fadeOut = keyframes`
  0% {
    opacity: 100%;
  }
  30% {
    opacity: 90%;
  }
  60% {
    opacity: 60%;
  }
  80% {
    opacity: 40%;
  }
  100% {
    opacity: 0;
  }
`;

export const WelcomeDiv = styled.div<{ isVisible: boolean }>`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-family: "Noto Serif Khitan Small Script", serif;
  font-style: normal;
  text-align: center;
  display: flex;
  animation: ${({ isVisible }) => (isVisible ? fadeOut : "")} 3.1s
    cubic-bezier(0.4, 0, 1, 1);
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
`;

export const InfoDiv = styled.div`
  background-color: #fff;
  z-index: 1;
`;

export const LogoImg = styled.img`
  width: 300px;
  height: 300px;
`;

export const TopText = styled.h1`
  border-bottom: 1px solid #fff;
`;
