import styled from "styled-components";

export const MainPageSection = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
`;

export const GoBack = styled.a`
  position: fixed;
  bottom: 5%;
  right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px 20px;
  background-image: linear-gradient(
    90deg,
    rgba(91, 26, 92, 1) 0%,
    rgba(41, 53, 86, 1) 50%,
    rgba(14, 9, 43, 1) 100%
  );
  color: #fff;
  cursor: pointer;
  z-index: 100;
`;

export const GoBackIcon = styled.img`
  width: 20px;
  height: 25px;
`;

export const UpperSection = styled.div`
  background: url(${process.env.PUBLIC_URL}/assets/background.webp);
  font-family: Arial, Helvetica, sans-serif;
  gap: 80px;
  user-select: none;
  background-size: cover;
  width: 100vw;
`;

export const HeightContainer = styled.div`
  height: 100vh;
`;

export const MenuRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 15px 30px;
`;

export const Logo = styled.img`
  width: 200px;
  height: 200px;
  transform: translateX(-40px);
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 15px;
  list-style: none;
`;

export const LinkElement = styled.li`
  text-decoration: none;
`;

export const Link = styled.a`
  text-decoration: none;
  color: #fff;
  cursor: pointer;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 140px 80px 140px;

  @media (max-width: 880px) {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 20px 80px;
  }
`;

export const MiddleSection = styled.div`
  background-color: #fff;
  position: relative;
  color: #000;
  width: 100vw;
`;

export const SliderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  text-align: center;
  padding: 0 140px;
  /* border-bottom: 1px solid #eee; */
  padding-bottom: 50px;

  @media (max-width: 880px) {
    margin: unset;
    padding: unset;
    padding: 50px 0;
    border-bottom: unset;
  }
`;

export const ShowMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 15px 20px;
  background-image: linear-gradient(
    90deg,
    rgba(91, 26, 92, 1) 0%,
    rgba(41, 53, 86, 1) 50%,
    rgba(14, 9, 43, 1) 100%
  );
  color: #fff;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OffertHeader = styled.h2`
  font-size: 29px;
`;

export const InfoHeader = styled.h2`
  font-weight: bold;
  font-size: 60px;
  margin: 0;
`;

export const InfoText = styled.p`
  line-height: 28px;
`;

export const WaveImage = styled.img`
  transform: translate(0px, -99%);
  width: 100vw;
  position: absolute;
  user-select: none;
`;

export const AboutSection = styled.div`
  padding: 20px 140px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  /* background: url(${process.env.PUBLIC_URL}/assets/layered-waves.svg); */
  color: #000;

  @media (max-width: 880px) {
    margin: unset;
    padding: unset;
    padding: 10px 0;
  }
`;

export const AboutUsHeader = styled.h2`
  font-size: 45px;

  @media (max-width: 880px) {
    margin-bottom: unset;
    padding: unset;
    padding: 10px 0;
    font-size: 28px;
  }
`;

export const ContactUsHeader = styled.h2`
  font-size: 45px;
  margin-bottom: 10px;

  @media (max-width: 880px) {
    margin-bottom: unset;
    padding: unset;
    padding: 10px 0;
    font-size: 28px;
  }
`;

export const ContactUsText = styled.h2`
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 60px;

  @media (max-width: 880px) {
    margin-bottom: unset;
    padding: unset;
    padding: 10px 0;
    font-size: 16px;
  }
`;

export const OfferText = styled.p`
  line-height: 30px;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 880px) {
    font-size: unset;
  }
`;

export const AboutRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* justify-content: space-between; */
  /* align-items: flex-end; */
  box-sizing: border-box;
  width: 60%;

  @media (max-width: 880px) {
    margin: unset;
    padding: 0 20px;
    width: 100vw;
    text-align: center;
    gap: 10px;
    position: relative;
  }
`;

export const CircleContainer = styled.div`
  position: relative;
  width: 35%;
  box-sizing: border-box;
`;

export const OfferSection = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 40px;
  padding: 140px;

  @media (max-width: 880px) {
    padding: 10px;
  }
`;

export const ContactUs = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 40px;
  padding: 30px 140px;
  background-image: url(${process.env.PUBLIC_URL}/assets/layered-waves.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
  align-items: center;
  justify-content: center;

  @media (max-width: 880px) {
    padding: 30px;
  }
`;
