import styled from "styled-components";

export const Slide = styled.div<{ active: boolean }>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: 0.5s;
  height: ${({ active }) => (active ? "230px" : "220px")} !important;
  width: 400px !important;
  color: #000;
  font-size: 1rem;
  background-color: #fff;
  -webkit-box-shadow: ${({ active }) =>
    active
      ? "8px 8px 15px 0px rgba(138, 43, 226, 0.5)"
      : "1px 1px 5px 0px rgba(138, 43, 226, 0.5)"};
  -moz-box-shadow: ${({ active }) =>
    active
      ? "8px 8px 15px 0px rgba(138, 43, 226, 0.5)"
      : "1px 1px 5px 0px rgba(138, 43, 226, 0.5)"};
  box-shadow: ${({ active }) =>
    active
      ? "8px 8px 15px 0px rgba(138, 43, 226, 0.5)"
      : "1px 1px 5px 0px rgba(138, 43, 226, 0.5)"};
  border: 0.5px solid rgba(138, 43, 226, 0.5);
  border-radius: 9px;
  z-index: ${({ active }) => (active ? "5" : "1")};
  padding: 0 20px;
  transform: ${({ active }) => (active ? "scale(1.5)" : "scale(1)")};

  @media (max-width: 768px) {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    box-sizing: border-box;
    transform: unset;
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 0 10px;
  }

  @media (max-width: 1200px) {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    box-sizing: border-box;
    transform: unset;
    border: unset;
    box-shadow: unset;
    border-radius: unset;
    padding: 0 10px;
  }
`;

export const Icon = styled.div<{ positionX: string; positionY: string }>`
  top: ${({ positionY }) => positionY}%;
  right: ${({ positionX }) => positionX}%;
  position: absolute;
  z-index: 1;
`;

export const Text = styled.div<{ active: boolean }>`
  z-index: 4;
  filter: ${({ active }) => (active ? "unset" : "blur(1px)")};
`;

export const Img = styled.img`
  width: 80px;
  height: 80px;
  transform: rotate(100deg);
`;
