import React, { useEffect, useRef, useState } from "react";
import * as S from "./Offer.styles";

const Offer = () => {
  const offersList = [
    {
      title: "Stworzenie Strony Internetowej",
      icon: "www",
      description:
        "Nasza firma oferuje kompleksową usługę tworzenia stron internetowych, dostosowanych do indywidualnych potrzeb klienta. Z nami zrealizujesz swoją wizję od pomysłu po gotowy produkt, który zachwyci Twoich klientów i wyróżni się na rynku. Zaprojektujemy, stworzymy oraz ustawimy Twoją stronę.",
    },
    {
      title: "Prowadzenie Twojej Strony",
      icon: "team",
      description:
        "Zarządzanie stroną internetową to kluczowy element sukcesu Twojego biznesu w sieci. Nasza firma oferuje kompleksowe usługi prowadzenia strony internetowej - aktualizowanie oraz dodawanie treści, dzięki którym możesz skupić się na rozwoju swojej działalności, mając pewność, że Twoja witryna jest zawsze aktualna i przyciąga klientów.",
    },
    {
      title: "Prowadzenie Social Media",
      icon: "link",
      description:
        "Nasza firma oferuje kompleksową obsługę i prowadzenie Twoich profili w mediach społecznościowych, co pozwoli Ci zbudować silną obecność online, zwiększyć zaangażowanie odbiorców oraz efektywnie promować swoją markę. Z nami Twoje profile w social mediach będą żywe, autentyczne i przyciągające uwagę.",
    },
    {
      title: "Audyty oraz Optymalizacja SEO",
      icon: "seo",
      description:
        "Nasza firma specjalizuje się w przeprowadzaniu kompleksowych audytów SEO oraz optymalizacji stron internetowych, aby zwiększyć widoczność Twojej strony w wynikach wyszukiwania i przyciągnąć więcej potencjalnych klientów. Dzięki naszym usługom, Twoja strona stanie się bardziej konkurencyjna, a ruch znacząco wzrośnie.",
    },
    {
      title: "Konsultacje E-Marketingowe / Analiza Rynku",
      icon: "talk",
      description:
        "Nasza firma oferuje analizę rynku wraz z analizą słów kluczowych, profesjonalne konsultacje e-marketingowe, które pomogą Ci skutecznie zaistnieć w świecie cyfrowym i osiągnąć zamierzone cele biznesowe. Z naszą pomocą zwiększysz swoją obecność w internecie, przyciągniesz więcej klientów i maksymalnie wykorzystasz potencjał nowoczesnych narzędzi marketingowych.",
    },
    {
      title: "Całkowite Wsparcie Techniczne",
      icon: "support",
      description:
        "Zajmujemy się kompleksowym wsparciem technicznym Twojej strony internetowej, abyś mógł skupić się na rozwoju swojego biznesu, nie martwiąc się o żadne kwestie techniczne. Dzięki naszej usłudze masz pewność, że Twoja strona działa bez zarzutu, a wszystkie techniczne aspekty są w pełni zarządzane przez profesjonalistów.",
    },
    {
      title: "Pozyskiwanie partnerów / sponsorów",
      icon: "team",
      description:
        "Im większy ruch generujesz w sieci, tym więcej firm chce z Tobą współpracować. Nasza firma specjalizuje się w pozyskiwaniu strategicznych partnerów i sponsorów, którzy pomogą Twojej marce rozwijać się i osiągać ambitne cele. Dzięki naszym usługom zbudujesz trwałe relacje biznesowe, zwiększysz rozpoznawalność swojej marki oraz uzyskasz wsparcie finansowe i merytoryczne niezbędne do realizacji kluczowych projektów.",
    },
    {
      title: "Kontrola budżetu i analiza kosztów",
      icon: "dollar",
      description:
        "Nasza firma oferuje profesjonalne usługi kontroli budżetu oraz analizy kosztów związanych z utrzymaniem strony internetowej, dzięki czemu możesz zoptymalizować swoje wydatki, zapewniając jednocześnie maksymalną efektywność i stabilność infrastruktury. Pomagamy firmom skutecznie zarządzać kosztami, eliminować zbędne wydatki i maksymalizować zwrot z inwestycji w reklamę online.",
    },
  ];

  const [isVisible, setIsVisible] = useState(false);
  const [isShowMoreSet, setIsShowMoreSet] = useState(false);
  const domRef = useRef<any>();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    observer.observe(domRef.current);
    return () => observer.disconnect();
  }, [domRef.current]);

  return (
    <>
      <S.OfferHeader data-test-id="Offer_Header">
        W Czym Ci Pomożemy
      </S.OfferHeader>
      <S.OfferRow data-test-id="Offer_Row" ref={domRef} isVisible={isVisible}>
        {offersList.map(({ title, icon, description }) => (
          <S.OfferTile>
            <S.IconContainer>
              <S.Icon
                src={`${process.env.PUBLIC_URL}/assets/${icon}.svg`}
                alt={title}
              />
            </S.IconContainer>
            <S.TileHeader>{title}</S.TileHeader>
            <S.DescriptionText>{description}</S.DescriptionText>
          </S.OfferTile>
        ))}
      </S.OfferRow>
    </>
  );
};

export default Offer;
